// Components
import {
	popupBox,
	columnsHover,
	columnsLightBox,
	footer,
	navigation,
	menu,
	videoFullScreen,
	accordionHorizontal,
	sliderMobile,
	slider,
	sliderPartial,
	formControl,
	header,
	scrollingImageContent,
	teamSliderMobile,
} from './components';

// Libraries
import $ from 'jquery';
import 'slick-carousel';

class App {
	constructor(page, initialized) {
		this.page = page;
		this.initialized = initialized;
		this.isMobile = window.matchMedia('(max-width: 768px)').matches;

		if (this.initialized == false) {
			if (document.readyState === 'loading') {
				document.addEventListener('DOMContentLoaded', this.init());
			} else {
				this.init();
			}
		}
	}

	init() {
		this.initialized = true;
		footer($);
		navigation();
		popupBox();

		formControl($);
		header();

		switch (true) {
			case this.page.classList.contains('home'):
				videoFullScreen();
				sliderMobile($);
				slider($);
				columnsHover();
				accordionHorizontal($);
				break;
			case this.page.classList.contains('page-template-template-sauces'):
				slider($);
				columnsLightBox();
				break;
			case this.page.classList.contains(
				'page-template-template-reservations'
			):
				sliderMobile($);
				break;
			case this.page.classList.contains('page-template-template-menus'):
				menu();
				sliderPartial($);
				break;
			case this.page.classList.contains(
				'page-template-template-about-our-story'
			):
				scrollingImageContent();
				teamSliderMobile($);
				break;
		}
	}
}

new App(document.querySelector('body'), false);
