const navigation = () => {
	// Sub Menu Handler
	document.querySelectorAll('.menu-item-has-children').forEach((menuItem) => {
		// Click event
		menuItem.addEventListener('click', (e) => {
			e.stopPropagation();

			resetMenu();
			openSubMenu(menuItem);
		});

		// Hover event
		menuItem.addEventListener('mouseover', () => {
			resetMenu(menuItem);
			openSubMenu(menuItem);
		});

		menuItem
			.querySelector('.sub-menu')
			.addEventListener('mouseover', () => {
				resetMenu(menuItem);
				openSubMenu(menuItem);
			});

		menuItem.querySelector('.sub-menu').addEventListener('mouseout', () => {
			closeSubMenu(menuItem);
		});
	});

	// Prevent sub-menu click propagation
	document.querySelectorAll('.sub-menu').forEach((subMenu) => {
		subMenu.addEventListener('click', (e) => {
			e.stopPropagation();
		});
	});

	// Mobile Menu Handler
	const hamburger = document.querySelector('.hamburger');
	const nav = document.querySelector('.nav-container');

	if (hamburger && nav) {
		hamburger.addEventListener('click', () => {
			resetMenu();
			hamburger.classList.toggle('open');
			nav.classList.toggle('open');
		});
	}

	// Close menu on sub-menu item click
	document.querySelectorAll('.sub-menu a').forEach((link) => {
		link.addEventListener('click', (e) => {
			if (hamburger && nav) {
				hamburger.classList.toggle('open');
				nav.classList.toggle('open');
			}
			e.stopPropagation();
		});
	});

	// Function to reset all menus
	function resetMenu(currentItem) {
		document.querySelectorAll('.menu-item-has-children').forEach((item) => {
			if (currentItem === item) return;
			item.classList.remove('open');
			const link = item.querySelector('a');
			const subMenu = item.querySelector('.sub-menu');
			if (link) link.classList.remove('open');
			if (subMenu) subMenu.classList.remove('open');
		});
	}

	// Function to toggle a submenu
	function toggleSubMenu(parent) {
		const subMenu = parent.querySelector('.sub-menu');
		const firstLink = parent.querySelector('a');
		const isOpen = parent.classList.contains('open');
		resetMenu();
		if (!isOpen) {
			parent.classList.add('open');
			if (firstLink) firstLink.classList.add('open');
			if (subMenu) subMenu.classList.add('open');
		}
	}

	// Function to open a submenu
	function openSubMenu(parent) {
		const subMenu = parent.querySelector('.sub-menu');
		const firstLink = parent.querySelector('a');
		parent.classList.add('open');
		if (firstLink) firstLink.classList.add('open');
		if (subMenu) subMenu.classList.add('open');
	}

	// Function to close a submenu
	function closeSubMenu(parent) {
		const subMenu = parent.querySelector('.sub-menu');
		const firstLink = parent.querySelector('a');
		parent.classList.remove('open');
		if (firstLink) firstLink.classList.remove('open');
		if (subMenu) subMenu.classList.remove('open');
	}
};

export default navigation;
