const sliderPartial = ($) => {
	const sliderContainer = document.querySelectorAll(
		'.partial_slider__slider__container'
	);

	sliderContainer.forEach((slider) => {
		$(slider).slick({
			autoplay: false,
			infinite: true,
			dots: true,
			arrows: true,
			prevArrow: document.querySelector('.prev.arrow'),
			nextArrow: document.querySelector('.next.arrow'),
		});
		// Re-initialize slider on changes
		$(window).on('load resize orientationchange', function () {
			if ($(slider).hasClass('slick-initialized')) {
				$(slider).slick('unslick');
			}
			$(slider).slick({
				autoplay: false,
				infinite: true,
				dots: true,
				arrows: true,
				prevArrow: document.querySelector('.prev.arrow'),
				nextArrow: document.querySelector('.next.arrow'),
			});
		});
	});
};

export default sliderPartial;
