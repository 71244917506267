const columnsHover = () => {
	const component = document.querySelector('#component_columns');
	const cards = component.querySelectorAll('.partial_card_image__container');

	cards.forEach((card) => {
		card.addEventListener('mouseenter', () => {
			const image = card.querySelector('img');
			image.style.transform = 'scale(1.1)';
		});

		card.addEventListener('mouseleave', () => {
			const image = card.querySelector('img');
			image.style.transform = 'scale(1)';
		});
	});
};

export default columnsHover;
