const teamSliderMobile = ($) => {
	const cardsSliderContainer = document.querySelector(
		'.component_team_grid__cards'
	);
	const cards = document.querySelectorAll('.component_team_grid__card');

	$(cardsSliderContainer).slick({
		autoplay: false,
		infinite: false,
		dots: false,
		arrows: false,
		mobileFirst: true,
		slidesToShow: 1.25,
		initialSlide: 0,
		centerMode: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: 'unslick',
			},
		],
	});
	// Re-initialize slider on changes
	$(window).on('load resize orientationchange', function () {
		if ($(cardsSliderContainer).hasClass('slick-initialized')) {
			$(cardsSliderContainer).slick('unslick');
		}
		$(cardsSliderContainer).slick({
			autoplay: false,
			infinite: false,
			dots: false,
			arrows: false,
			mobileFirst: true,
			slidesToShow: 1.25,
			initialSlide: 0,
			centerMode: false,
			responsive: [
				{
					breakpoint: 1024,
					settings: 'unslick',
				},
			],
		});
	});
};

export default teamSliderMobile;
