const slider = ($) => {
	$(window).on('load', function () {
		const sliderContainer = document.querySelectorAll(
			'.component_slider__slider__container'
		);
		const lightbox = document.querySelector('.component_slider__lightbox');
		const close = document.querySelector(
			'.component_slider__lightbox__close-btn'
		);

		close.addEventListener('click', () => {
			lightbox.classList.remove('open');
		});

		const initializeSlider = (slider) => {
			$(slider).slick({
				autoplay: false,
				infinite: true,
				adaptiveHeight: true,
				dots: true,
				arrows: true,
				prevArrow: document.querySelector('.prev.arrow'),
				nextArrow: document.querySelector('.next.arrow'),
			});

			// Force Slick to adjust dimensions
			setTimeout(() => {
				$(slider).slick('setPosition');
			}, 500);
		};

		const observer = new IntersectionObserver(
			(entries, observer) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						initializeSlider(entry.target);
						observer.unobserve(entry.target);
					}
				});
			},
			{ threshold: 0.1 }
		);

		sliderContainer.forEach((slider) => {
			const galleryImage = slider.querySelectorAll(
				'.partial_card_image__container'
			);

			observer.observe(slider);

			// Re-initialize slider on changes
			$(window).on('resize orientationchange', function () {
				if ($(slider).hasClass('slick-initialized')) {
					$(slider).slick('unslick');
				}

				$(slider).slick({
					autoplay: false,
					infinite: true,
					dots: true,
					arrows: true,
					adaptiveHeight: true,
					prevArrow: document.querySelector('.prev.arrow'),
					nextArrow: document.querySelector('.next.arrow'),
				});
			});

			galleryImage.forEach((image) => {
				image.addEventListener('click', (e) => {
					let imageSrc = e.target.src;
					let imageAlt = e.target.alt;
					let image = lightbox.querySelector('img');

					image.alt = imageAlt;
					image.src = imageSrc;
					lightbox.classList.add('open');
				});
			});
		});
	});
};

export default slider;
