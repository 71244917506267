function initHideNavOnScroll() {
	const header = document.querySelector('header');

	if (!header) return;

	let lastScrollPosition = 0;

	const onScroll = () => {
		const currentScrollPosition = window.scrollY;

		if (currentScrollPosition === 0) {
			header.classList.remove('active');
		} else {
			header.classList.add('active');
		}
	};

	window.addEventListener('scroll', onScroll);
}

const header = () => {
	document.addEventListener('DOMContentLoaded', function () {
		initHideNavOnScroll();
	});
};

export default header;
