const accordionHorizontal = ($) => {
	const cardsSliderContainer = document.querySelector(
		'.component_accordion_horizontal__cards.slider'
	);
	const cards = document.querySelectorAll(
		'.component_accordion_horizontal__card'
	);

	const clearCards = (cards) => {
		cards.forEach((card) => {
			if (card.classList.contains('active')) {
				card.classList.remove('active');
			}
		});
	};

	cards.forEach((card, index) => {
		if (index == 1 || index == 2) {
			card.addEventListener('mouseenter', () => {
				cards[0].classList.add('inactive');
			});
			card.addEventListener('click', () => {
				cards[0].classList.add('inactive');
			});
			card.addEventListener('click', () => {
				clearCards(cards);
				card.classList.add('active');
			});
			card.addEventListener('mouseleave', () => {
				cards[0].classList.remove('inactive');
			});
		} else {
			card.addEventListener('click', () => {
				clearCards(cards);
				cards[0].classList.remove('inactive');
				card.classList.add('active');
			});
		}
	});

	$(cardsSliderContainer).slick({
		autoplay: false,
		infinite: true,
		dots: false,
		arrows: false,
		mobileFirst: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: 'unslick',
			},
		],
	});
	// Re-initialize slider on changes
	$(window).on('load resize orientationchange', function () {
		if ($(cardsSliderContainer).hasClass('slick-initialized')) {
			$(cardsSliderContainer).slick('unslick');
		}
		$(cardsSliderContainer).slick({
			autoplay: false,
			infinite: true,
			dots: true,
			arrows: false,
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 1024,
					settings: 'unslick',
				},
			],
		});
	});
};

export default accordionHorizontal;
