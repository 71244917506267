const formControl = ($) => {
	const joinTheTeam = ($) => {
		$('#field_3_10').on('change', function () {
			$('.form').removeClass('initial');
		});
	};

	const privateDining = ($) => {
		$('#field_2_10').on('change', function () {
			$('.form').removeClass('initial');
		});
	};

	const contact = ($) => {
		$('#field_4_10').on('change', function () {
			$('.form').removeClass('initial');
		});
	};

	if ($('body').hasClass('page-template-template-about-join-the-team')) {
		joinTheTeam($);
	}

	if ($('body').hasClass('page-template-template-private-dining')) {
		privateDining($);
	}

	if ($('body').hasClass('page-template-template-contact')) {
		contact($);
	}
};

export default formControl;
