const menu = () => {
	const menu = document.querySelector('.component_menu__menu');
	const tabs = document.querySelectorAll(
		'.component_menu__category-tabs ul li'
	);
	const categories = document.querySelectorAll('.component_menu__category');
	const dietaryFilters = document.querySelectorAll(
		'.component_menu__dietary-filters li'
	);
	const menuSelect = document.querySelector(
		'.component_menu__category-tabs select'
	);

	menuSelect.addEventListener('change', () => {
		let selectedCategory = menuSelect.value;
		window.location.href = selectedCategory;
	});

	tabs.forEach((tab) => {
		tab.addEventListener('click', () => {
			let activeCategory = tab.getAttribute('data-tab');
			categories.forEach((category) => {
				menu.classList.add('fade-out');

				setTimeout(() => {
					category.classList.remove('active');

					if (
						category.getAttribute('data-category') ===
						activeCategory
					) {
						category.classList.add('active');
					}
				}, 500);

				setTimeout(() => {
					menu.classList.remove('fade-out');
					menu.classList.add('fade-in');
				}, 500);
				setTimeout(() => {
					menu.classList.remove('fade-in');
				}, 500);
			});
			tabs.forEach((tab) => {
				tab.classList.remove('active');
			});
			tab.classList.add('active');
		});
	});

	dietaryFilters.forEach((filter) => {
		filter.addEventListener('click', () => {
			let activeFilter = filter.getAttribute('data-filter');
			filter.classList.toggle('active');
			menu.classList.toggle(activeFilter);
		});
	});
};

export default menu;
