const columnsLightBox = () => {
	const lightbox = document.querySelector('.partial_card_image__lightbox');
	const trigger = document.querySelector(
		'.partial_card_image__lightbox_trigger'
	);
	const video = lightbox.querySelector('video');
	const close = document.querySelector(
		'.partial_card_image__lightbox__close-btn'
	);

	close.addEventListener('click', () => {
		lightbox.classList.remove('open');
		video.pause();
	});

	trigger.addEventListener('click', (e) => {
		lightbox.classList.toggle('open');
		video.play();
	});
};

export default columnsLightBox;
