const sliderMobile = ($) => {
	const sliderContainer = document.querySelectorAll(
		'.partial_slider_mobile__slider'
	);

	sliderContainer.forEach((slider) => {
		$(slider).slick({
			autoplay: false,
			infinite: true,
			dots: false,
			arrows: false,
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 1024,
					settings: 'unslick',
				},
			],
		});
		// Re-initialize slider on changes
		$(window).on('load resize orientationchange', function () {
			if ($(slider).hasClass('slick-initialized')) {
				$(slider).slick('unslick');
			}
			$(slider).slick({
				autoplay: false,
				infinite: true,
				dots: true,
				arrows: false,
				mobileFirst: true,
				responsive: [
					{
						breakpoint: 1024,
						settings: 'unslick',
					},
				],
			});
		});
	});
};

export default sliderMobile;
