const scrollingImageContent = () => {
	function vh(percent) {
		var h = Math.max(
			document.documentElement.clientHeight,
			window.innerHeight || 0
		);
		return (percent * h) / 100;
	}

	const componentContainer = document.querySelector(
		'.component_scrolling_image_content__container'
	);
	const contentContainer = document.querySelector(
		'.component_scrolling_image_content__content'
	);
	const images = componentContainer.querySelectorAll(
		'.component_scrolling_image_content__images img'
	);

	document.addEventListener('scroll', () => {
		const scrollPosition = window.scrollY;
		const image = images[1];
		const imagePosition = image.offsetTop;

		if (imagePosition + vh(35) < scrollPosition) {
			images[0].classList.remove('active');
			image.classList.add('active');
		} else {
			image.classList.remove('active');
			images[0].classList.add('active');
		}
	});
};

export default scrollingImageContent;
